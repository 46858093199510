import 'sites/happybet/styles/app.scss';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { AKAMAI_SESSION_COOKIE, FORCE_LOGOUT, IS_LOGGED, isGermanSite } from 'utility/constant';
import Persist, { StorageKind } from 'lib/persist';
import { appInsight, appInsightPageLoad } from 'components/appInsight';
import { isClientSide, isTruthy } from 'utility/functions';
import { useEffect, useMemo, useRef } from 'react';

import { AppProps } from 'next/app';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import { I18nProvider } from '@lingui/react';
import type { Metric } from 'web-vitals';
import { Provider } from 'react-redux';
import React from 'react';
import { RefreshTokenEngine } from 'components/refreshTokenEngine';
import Script from 'next/script';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ThemeProvider } from 'next-themes';
import { fromSearchSetSecondary } from 'lib/mwNodeSwitch';
import { getConfig } from 'features/configuration/actions';
import { initZodLabels } from 'lib/labels';
import { navigate } from 'features/location/types';
import policy from 'lib/policy/types';
import { store } from 'sites/happybet/lib/store';
import { useInitLingUI } from 'hooks/useLingUI';
import { useRouter } from 'next/router';
import { WrapperInactiveLogout } from 'components/wrapperInactiveLogout';
import { ModalsContext } from 'sites/happybet/context/ModalsContext';
import GoogleTagManager from 'sites/happybet/components/googleTagManager';
import { cookieReset } from 'lib/persist/cookie';
import { decrementTabCount, getTabCount, incrementTabCount } from 'utility/tabCounter';

const { ACSBAPP_URL } = policy;

export const trackWV = (metric: Metric, pathName: string, tagName = 'Web Vitals') => {
  const { name, value } = metric;
  appInsight.trackTrace({
    message: `[${tagName}] ${pathName} - ${name} : ${value}`,
    severityLevel: SeverityLevel.Information,
  });
};

// await is required because of signalR connection dependenry
const { getState, dispatch } = store;
await dispatch(getConfig());

initZodLabels();

const cookie = Persist(StorageKind.cookie);

export default function MyApp({ Component, pageProps }: AppProps<{ session: Session }>) {
  const { asPath, events, replace } = useRouter();

  const { messages, modalItems } = useMemo(() => {
    const labels = Reflect.get(pageProps ?? {}, 'labels');
    const allLabels = Reflect.get(pageProps ?? {}, 'allLabels');
    const messages = Object.assign({}, labels, allLabels);
    const modalItems = Reflect.get(pageProps ?? {}, 'allModalItems') ?? [];

    return {
      messages,
      modalItems,
    };
  }, [pageProps]);

  // TODO : set cms labels always to labels
  const i18n = useInitLingUI(messages);

  const path = useMemo(() => {
    const [path] = asPath.split(/\?|#/gim);
    return decodeURI(path);
  }, [asPath]);

  const initialized = useRef(false);
  useEffect(() => {
    if (isClientSide() && !initialized.current) {
      initialized.current = true;
      cookieReset();
      fromSearchSetSecondary(document.location.search);
    }
  }, []);

  /* useEffect(() => {
    setTimeout(() => {
      onCLS((m: Metric) => trackWV(m, path));
      onFID((m: Metric) => trackWV(m, path));
      onLCP((m: Metric) => trackWV(m, path));
      onINP((m: Metric) => trackWV(m, path));
      onFCP((m: Metric) => trackWV(m, path));
      onTTFB((m: Metric) => trackWV(m, path));
    }, 0);
  }, [path]); */

  useEffect(() => {
    incrementTabCount();

    const handleBeforeUnload = () => {
      const tabCount = getTabCount();
      if (tabCount === 1) {
        decrementTabCount();
        if (isTruthy(cookie.getItem(IS_LOGGED))) localStorage.setItem(FORCE_LOGOUT, 'true');
      } else decrementTabCount();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      decrementTabCount();
    };
  }, []);

  useEffect(() => {
    appInsightPageLoad(false);
    cookie.removeItem(AKAMAI_SESSION_COOKIE);
    dispatch(navigate(path));
  }, [path]);

  // Aggiorna il pathname corrente e precedente quando cambia la pagina
  useEffect(() => {
    const handleChangeStart = (pathname) => {
      appInsightPageLoad(true);
      dispatch({ type: navigate.pending.type, meta: { arg: decodeURI(pathname) } });
    };

    const handleChangeError = (pathname) => {
      appInsightPageLoad(false, true);
      dispatch({ type: navigate.rejected.type, payload: decodeURI(pathname) });
    };

    const handleChangeComplete = (pathname) => {
      appInsightPageLoad(false);
      dispatch({ type: navigate.fulfilled.type, payload: decodeURI(pathname) });
    };

    events.on('routeChangeError', handleChangeError);
    events.on('routeChangeStart', handleChangeStart);
    events.on('routeChangeComplete', handleChangeComplete);

    // If the component is unmounted, unsubscribe  from the event with the `off` method:
    return () => {
      events.off('routeChangeError', handleChangeError);
      events.off('routeChangeStart', handleChangeStart);
      events.off('routeChangeComplete', handleChangeComplete);
    };
  }, [events]);

  return (
    <>
      <Provider store={store} serverState={getState()}>
        <I18nProvider i18n={i18n}>
          <ThemeProvider enableSystem={false} defaultTheme="dark">
            <SessionProvider session={pageProps.session}>
              <ModalsContext.Provider value={modalItems}>
                <ErrorBoundary asPath={asPath} replace={replace} session={pageProps.session} key={i18n.locale}>
                  <Component {...pageProps} key={i18n.locale} />
                </ErrorBoundary>
                <RefreshTokenEngine />
                <WrapperInactiveLogout />
              </ModalsContext.Provider>
            </SessionProvider>
          </ThemeProvider>
        </I18nProvider>
      </Provider>
      <GoogleTagManager />
      {/*EMX <!-- Conversion Pixel - Cormes_HappyBet - Month Test 2021_Z20677_ PAGE VIEW CONV - DO NOT MODIFY -->*/}
      <Script id="emx" src="https://secure.adnxs.com/px?id=1475288&t=1" />
      {/*BING*/}
      {isGermanSite ? (
        <Script id="bing">{`
(function(w,d,t,r,u) { var f,n,i; w[u]=w[u]||[],f=function() { var o={ti:"97016179"}; o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad") }, n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function() { var s=this.readyState; s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null) }, i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i) }) (window,document,"script","//bat.bing.com/bat.js","uetq");
`}</Script>
      ) : (
        <Script id="bing">{`
(function(w,d,t,r,u) { var f,n,i; w[u]=w[u]||[],f=function() { var o={ti:"134611482"}; o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad") }, n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function() { var s=this.readyState; s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null) }, i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i) }) (window,document,"script","//bat.bing.com/bat.js","uetq");
`}</Script>
      )}
      {/*SNAPCHAT*/}
      {isGermanSite ? (
        <Script id="snapchat">{`
(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
{a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
r.src=n;var u=t.getElementsByTagName(s)[0];
u.parentNode.insertBefore(r,u);})(window,document,
'https://sc-static.net/scevent.min.js');
snaptr('init', 'ddd66af9-c30c-4354-83f7-2ee997522820', {
'user_email': '__INSERT_USER_EMAIL__'
});
snaptr('track', 'PAGE_VIEW');
`}</Script>
      ) : (
        <Script id="snapchat">{`
(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
{a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
r.src=n;var u=t.getElementsByTagName(s)[0];
u.parentNode.insertBefore(r,u);})(window,document,
'https://sc-static.net/scevent.min.js');
snaptr('init', 'ddd66af9-c30c-4354-83f7-2ee997522820', {
'user_email': '__INSERT_USER_EMAIL__'
});
snaptr('track', 'PAGE_VIEW');
`}</Script>
      )}

      {/*SPORTRADAR*/}
      {isGermanSite ? (
        <Script id="sportradar">{`(function (a, b, c, d, e, f) {a[e]=a[e]||[];a.x=b.createElement(c);a.y=b.getElementsByTagName(c)[0];x.src=d+'?id='+f;x.setAttribute('data-srtmn',e);y.parentNode.insertBefore(x,y);}(window, document, 'script', 'https://tm.ads.sportradar.com/dist/tag-manager.js', 'srtmCommands', 'STM-AAAAON'));`}</Script>
      ) : (
        <Script id="sportradar">{`(function (a, b, c, d, e, f) {a[e]=a[e]||[];a.x=b.createElement(c);a.y=b.getElementsByTagName(c)[0];x.src=d+'?id='+f;x.setAttribute('data-srtmn',e);y.parentNode.insertBefore(x,y);}(window, document, 'script', 'https://tm.ads.sportradar.com/dist/tag-manager.js', 'srtmCommands', 'STM-AAAAOO'));`}</Script>
      )}

      {isTruthy(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID?.length) && (
        <Script id="facebook-pixel">{`
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}');
      fbq('track', 'PageView');
      `}</Script>
      )}

      {/* accessiway: nsdev.snai.it - nstest.snai.it abilitati */}
      <Script id="accessiway" strategy="lazyOnload" src={ACSBAPP_URL}>{`
  (function () {
    var s = document.createElement("script"),
    e = !document.body ? document.querySelector("head") : document.body;
    s.src = "${ACSBAPP_URL}";
    s.async = true;
    s.onload = function () {        
      acsbJS.init({
        statementLink: "",
        footerHtml: '<a>AccessiWay. The Web Accessibility Solution</a>',
        hideMobile: false,
        hideTrigger: false,
        language: "it",
        position: "left",
        leadColor: "#E96105",
        triggerColor: "#E96105",
        triggerRadius: "50%",
        triggerPositionX: "left",
        triggerPositionY: "bottom",
        triggerIcon: "people",
        triggerSize: "small",
        triggerOffsetX: 8,
        triggerOffsetY: 104,
        mobile: {
          triggerSize: "small",
          triggerRadius: "50%",
          triggerOffsetX: 4,
          triggerOffsetY: 80,
          triggerPositionX: "left",
          triggerPositionY: "bottom",
        },
      });              
    };
    e.appendChild(s);
  })();`}</Script>
      <Script id="spotef" strategy="lazyOnload">
        {`var _spef = _spef || [];
 _spef.push(["enableLinkTracking"]);
 _spef.push(["trackPageView"]);
 (function () {
 var u = "//trck.spoteffects.net/analytics/";
 _spef.push(['setTrackerUrl', u + 'piwik.php']);
 _spef.push(['setSiteId',${process.env.NEXT_PUBLIC_SPOTEF_SITE_ID}]);
 var d = document, g = d.createElement("script"), s =
d.getElementsByTagName("script")[0];
 g.type = "text/javascript";
 g.defer = true;
 g.async = true;
 g.src = u + "spef.min.js";
 s.parentNode.insertBefore(g, s);
 })();`}
      </Script>
    </>
  );
}
